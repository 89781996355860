import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'

Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()

import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/aspectratio/ls.aspectratio';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js';
import lazySizes from 'lazysizes';

if(window.Alpine) {
  window.lazySizes.init();
}